* {
  box-sizing: border-box;
}

h1, h2, h3, p {
  margin-top: 0;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  border: none;
  padding: 0.5em;
}

label, input, select, textarea {
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
}

.form-inline label, .form-inline input, .form-inline select {
  display: inline;
  width: auto;
}

.app {
  height: 100vh;
  display: grid;
  grid-template-rows: 70px 1fr 1fr;
  grid-template-columns: none;
}

.header-top {
  grid-row: 1 / 2;
  padding: 1em;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.heading-withbutton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.intro {
  padding: 1em;
  grid-row: 2 / 3;
  background-color: #fff;
}

.notes {
  padding: 1em;
  grid-row: 3 / 4;
  background-color: #eee;
}

.notes-form {
  padding: 1em;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  background-color: #cef;
  z-index: 10;
}

.notes-form-enter {
  transform: translateX(-100%);
  transition: .2s ease;
}

.notes-form-enter-active {
  transform: translateX(0%);
}

.notes-form-exit {
  transform: translateX(0%);
  transition: .2s ease;
}

.notes-form-exit-active {
  transform: translateX(-100%);
}

.form-error {
  margin-bottom: 0.5em;
  color: #c00;
}

.card {
  background-color: #fff;
  padding: 0.5em;
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .app {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 70px 1fr;
  }
  .header-top {
    grid-column: 1 / 3;
  }

  .intro {
    grid-column: 1 / 2;
  }
  
  .notes {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}